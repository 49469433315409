body {
  background: black;
  color: white;
  font-size: calc(12px + 6 * ((100vw - 320px) / 680));
  font-family: "Press Start 2P", cursive;
}

#walletconnect-wrapper,
#WEB3_CONNECT_MODAL_ID {
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

input,
button {
  font-family: "Press Start 2P", cursive;
}

@keyframes fallingCoin {
  0% {
    /* transform: scaleX(var(--scaleX)); */
    top: calc(-1vw - 44px);
  }

  99% {
    /* transform: scaleX(var(--scaleX)); */
    top: 105vh;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fallingCoin {
  animation-name: fallingCoin;
  animation-duration: var(--duration);
  animation-delay: var(--delay);
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  position: fixed;
  top: calc(-1vw - 44px);
  left: var(--left);
  transform: scaleX(var(--scaleX));
  pointer-events: none;
  user-select: none;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vw;
  margin-bottom: 2vw;
  font-size: 1.5vw;
}

.footer a:link,
.footer a:visited {
  color: #555;
  text-decoration: none;
}

.footer a:hover {
  color: white;
  text-decoration: underline;
}

* {
  cursor: url("assets/cursor.png"), auto;
  cursor: -webkit-image-set(url("assets/cursor.png") 1x,
      url("assets/cursor@2x.png") 2x),
    auto;
}

a:hover,
a:hover span,
button {
  cursor: url("assets/cursor_pointer.png"), pointer;
  cursor: -webkit-image-set(url("assets/cursor_pointer.png") 1x,
      url("assets/cursor_pointer@2x.png") 2x),
    pointer;
}

@media screen and (min-width: 1000px) {
  body {
    font-size: 2vw;
  }
}

.form {
  display: block;
  margin: 1vw 0 2vw 0;
}

.form__fields {
  display: flex;
  gap: 4px;
  /* grid-template-columns: 3fr 1fr;
  grid-gap: 4px; */
}

.form__fields:focus-within {
  /* grid-template-columns: auto auto auto 25%; */
}

.form button {}

.form input {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
  text-transform: uppercase;
  transition: flex-basis ease-in-out 100ms;
  flex-basis: 25%;
}

.errorMessage {
  color: red;
  font-size: 1.2vw;
  margin-top: 0.5vw;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

a.transactionMessage:link,
a.transactionMessage:visited {
  color: white;
  font-size: 1.2vw;
  margin-top: 0.5vw;
  text-decoration: none;
  animation-name: blinker;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

a.transactionMessage:hover {
  text-decoration: underline;
}

.form__fields input,
.form__fields button {
  display: block;
  font-size: 3vw;
  padding: 0.5rem;
  background: #333;
  color: white;
  border: 4px #333 solid;
  outline: 0;
  box-sizing: border-box;
  flex: 1;
}

@keyframes pulsating {
  0% {
    border-color: #333;
  }

  100% {
    border-color: #fff;
  }
}

.input--pulsating {
  animation-name: pulsating;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.form__fields button.enabled {
  background-color: cyan;
  border-color: cyan;
  color: black;
}

/* .form__fields .grid div:focus-within {
  border: 2px red solid;
  min-width: 40vw;
} */

.form__fields input:focus {
  border-color: white;
  flex-basis: 10em;
}

.form input.error {
  border-color: red;
}

.form__fields button {
  background: #555;
  font-weight: bold;
  border-color: #555;
}

h1.header {
  display: block;
  text-transform: uppercase;
  font-size: calc(15px + 5vw);
  margin: 0;
  line-height: 1;
  padding-top: 2vw;
  overflow: hidden;
  white-space: nowrap;
  /* padding: 1vw; */
  /* color: #555; */
}

h1.header span {
  color: cyan;
  text-shadow: 2px 2px 0px blue;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-200%);
  }
}

h1.header>div:first-child {
  display: inline-block;
  animation-name: marquee;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: -6s;
}

h1.header>div:last-child {
  display: inline-block;
  animation-name: marquee2;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 4s;
}

.leaderboard {
  display: grid;
  /* grid-template-columns: 32px min-content 1fr min-content 32px; */
  grid-template-columns: min-content 1fr min-content;
  /* grid-column-gap: 3vw; */
  /* grid-row-gap: 1vw; */
  /* max-width: 40ch; */
  margin: 0 auto;
  /* margin-left: calc(-16px - 1rem); */
  /* padding: 0 calc(16px + 1rem); */
  text-transform: uppercase;
}

.leaderboard>div>div+div,
.leaderboard>.player>div+div {
  padding-left: 3vw;
  /* background: pink; */
}

.leaderboard>.player>div {
  padding: 0.6vw 0;
}

@media screen and (max-width: 1000px) {
  .leaderboard {
    grid-template-columns: min-content 1fr min-content;
  }
}

.nav {
  padding: 1em 1em 0 1em;
  display: flex;
  justify-content: space-between;
  font-size: 0.6em;
  text-transform: uppercase;
}

.nav a:link,
.nav a:visited,
.nav button {
  color: #555;
  text-decoration: none;
  background: none;
  border: none;
  font-size: inherit;
  text-transform: uppercase;
}

.nav a:hover,
.nav button:hover {
  color: white;
  text-decoration: underline;
}

.leaderboard {
  font-size: 2.9vw;
}

.content {
  /* padding: 2vw; */
}

.leaderboard__header {
  display: contents;
  /* text-shadow: 2px 2px 0px #095e56; */
  font-size: 0.5em;
}

.leaderboard__header__right {
  text-align: right;
}

.pointer {
  position: fixed;
  z-index: 5;
  transform-origin: center center;
  will-change: transform;
  transform: translateY(var(--top));
  cursor: pointer;
}

.pointer__inner {
  height: 2.8vw;
  width: calc(2.8vw * 1.6);
  background: url("assets/pointer.png") center center no-repeat;
  background-size: contain;
  transform-origin: center center;
  transform: rotate(var(--degree));
  margin-left: 1vw;
  /* image-rendering: pixelated; */
  /* image-rendering: -moz-crisp-edges; */
  /* image-rendering: crisp-edges; */
}

.hidden {
  display: none;
}

.player {
  display: contents;
  color: rgb(238, 238, 238);
  text-shadow: 0.05em 0.05em 0px rgb(87, 87, 87);

  /* Prevent emoji from breaking layout */
  /* line-height: 1em; */
}

@keyframes flicker {
  0% {
    background-color: white;
    color: black;
    text-shadow: none;
  }

  49% {
    background-color: white;
    color: black;
    text-shadow: none;
  }

  50% {}

  100% {}
}

.player--flicker>div {
  animation-name: flicker;
  animation-duration: 0.5s;
  animation-iteration-count: 10;
}

.player__rank {
  text-align: right;
}

.alignRight {
  text-align: right;
}

.player__score {
  white-space: nowrap;
  text-align: right;
}

.player a:link {
  text-decoration: none;
}

.player a:hover {
  /* text-decoration: underline; */
  border-bottom: 4px currentColor solid;
}

.player--current div {
  /* background: #333; */
}

.player--1st {
  color: rgb(255, 0, 0);
  text-shadow: 0.05em 0.05em 0px rgb(136, 0, 0);
}

.player--2nd {
  color: rgb(255, 0, 221);
  text-shadow: 0.05em 0.05em 0px rgb(136, 0, 95);
}

.player--3rd {
  color: #ff8800;
  text-shadow: 0.05em 0.05em 0px darkred;
}

.player--4th {
  color: rgb(251, 255, 0);
  text-shadow: 0.05em 0.05em 0px rgb(137, 139, 0);
}

.player--5th {
  color: rgb(51, 255, 0);
  text-shadow: 0.05em 0.05em 0px rgb(26, 129, 0);
}

.player--6th {
  color: rgb(0, 190, 127);
  text-shadow: 0.05em 0.05em 0px rgb(0, 63, 42);
}

.player--7th {
  color: rgb(0, 236, 185);
  text-shadow: 0.05em 0.05em 0px rgb(0, 139, 127);
}

.player--8th {
  color: rgb(0, 220, 236);
  text-shadow: 0.05em 0.05em 0px rgb(0, 123, 139);
}

.player--9th {
  color: rgb(0, 161, 236);
  text-shadow: 0.05em 0.05em 0px rgb(0, 65, 139);
}

.player--10th {
  color: rgb(0, 75, 236);
  text-shadow: 0.05em 0.05em 0px rgb(14, 0, 139);
}

.player--10xth {
  color: rgb(111, 0, 255);
  text-shadow: 0.05em 0.05em 0px rgb(72, 0, 131);
}

.player a:link,
.player a:visited {
  color: currentColor;
}

.totalPaid {
  text-align: center;
  margin-bottom: 2vw;
}

.spinnerCoin {
  margin: 5vw auto;
  height: 3vw;
  width: 3vw;
  display: block;
}

.totalPaid span {
  color: #555;
}

.pixelart {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}