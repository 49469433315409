.about {
  padding: 3vw;
  text-transform: uppercase;
  line-height: 1.5;
  font-size: 3vw;
  color: #aaa;
}

.about h1 {
  color: cyan;
  display: inline;
  font-size: 3vw;
}

.about h2 {
  font-size: 3vw;
  color: white;
  margin-top: 3em;
}

.about a:link,
.about a:visited {
  color: white;
}
