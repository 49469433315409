body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes flicker {
  0% {
    opacity: 0.10927;
  }
  5% {
    opacity: 0.14282;
  }
  10% {
    opacity: 0.38166;
  }
  15% {
    opacity: 0.14698;
  }
  20% {
    opacity: 0.10927;
  }
  25% {
    opacity: 0.26525;
  }
  30% {
    opacity: 0.94384;
  }
  35% {
    opacity: 0.32923;
  }
  40% {
    opacity: 0.061;
  }
  45% {
    opacity: 0.78527;
  }
  50% {
    opacity: 0.45051;
  }
  55% {
    opacity: 0.79637;
  }
  60% {
    opacity: 0.78713;
  }
  65% {
    opacity: 0.59027;
  }
  70% {
    opacity: 0.49948;
  }
  75% {
    opacity: 0.34297;
  }
  80% {
    opacity: 0.20971;
  }
  85% {
    opacity: 0.96214;
  }
  90% {
    opacity: 0.54802;
  }
  95% {
    opacity: 0.38727;
  }
  100% {
    opacity: 0.14962;
  }
}
.wrapper {
  background: #121010;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.content {
  padding: 0 5vw;
}

.wrapper::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
}
.wrapper::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.06),
      rgba(0, 255, 0, 0.02),
      rgba(0, 0, 255, 0.06)
    );
  z-index: 2;
  background-size:
    100% 2px,
    3px 100%;
  pointer-events: none;
}

.wrapper::after {
  animation: flicker 0.15s infinite;
}

@keyframes turn-on {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 1;
  }
  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }
  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }
  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 0;
  }
  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(0) brightness(0);
    filter: contrast(0) brightness(0);
    opacity: 0;
  }
  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(1) brightness(1.2) saturate(1.3);
    filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1;
  }
}
@keyframes turn-off {
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }
  100% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}
.screen {
  width: 100%;
  height: 100%;
  border: none;
}

/* .wrapper > .screen {
  animation: turn-off 0.55s cubic-bezier(0.23, 1, 0.32, 1);
  animation-fill-mode: forwards;
} */

.wrapper > .screen {
  animation: turn-on 3s linear;
  animation-fill-mode: forwards;
}

@keyframes overlay-anim {
  0% {
    visibility: hidden;
  }
  20% {
    visibility: hidden;
  }
  21% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}
.overlay {
  color: #00ff00;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 60px;
  visibility: hidden;
  pointer-events: none;
}

.wrapper .overlay {
  animation: overlay-anim 3s linear;
  animation-fill-mode: forwards;
}

.crt {
  display: none;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 98%,
    rgba(0, 0, 0, 1) 100%
  );
  z-index: 5;
}
